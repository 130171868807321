.carton_container-cart {
  background: url('/public/images/min/fondo_carton_min.png');
  width: 100px;
  height: 70px;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  gap: 5;
}

.carton_head-cart {
  width: 80px;
  height: 15px;
  background: green;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 600;
  font-size: 10px;
}

.carton_sorteo-cart {
  width: 80px;
  height: 15px;
  background: #fcefd1;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 8px;
}

.carton_sorteo_span .carton_individual-cart {
  margin-top: 10px;
}
