@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1a1a1a !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader { border-top-color: #aa1785; -webkit-animation: spin 1s linear infinite; animation: spin 1s linear infinite; } 

@-webkit-keyframes spin { 
  0% { -webkit-transform: rotate(0deg); } 
  100% { -webkit-transform: rotate(360deg); } } 

@keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); } }
